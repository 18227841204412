export default defineI18nConfig(() => ({
    legacy: false,
    strategy: "no_prefix",
    locale: "en",
    defaultLocale: "en", // default locale of your project for Nuxt pages and routings
    fallbackLocale: "en",
    messages: {
        en: {
            landingPageMainHero: "Find local doctors who take your insurance",
            landingPageMainHeroSubHeading:
                "We're enabling your U.S. health insurance to work seamlessly wherever you are",
            landingPageRecentNewsAnnouncement:
                "Announcing our newest partner, TuDOC.",
            landingPageRecentNewsCallToAction: "Sign up as a patient",
            getStarted: "Get started",
            learnMore: "Learn more",
            allRightsReserved: "All rights reserved",
            // forPatients: "For Patients",
            // forProviders: "For Providers",
            // yourAccount: "Your account",
            // yourClinic: "Dashboard",
            // signIn: "Sign in",
            providerHomeBannerFirstLine: "Insurance review in minutes.\n",
            providerHomeBannerSecondLine:
                "Submit your patient's insurance now.\n",
            providerHomeBannerCTAButtonText: "Submit insurance now",
            providerMostRecentClientListHeading: "Recent insurance approvals",

            whatsNew: "What's new",
            nowInMexico: "Now in Mexico",
            providersLandingPageHeroHeading:
                "The healthcare platform for international care.",
            providersLandingPageHeroDescriptionText:
                "NomadRx is the easiest way for your practice to manage your international clients. Provide a great patient experience, get paid quickly, and accept international insurance.",
            providersLandingPageInsuranceCloudHeader:
                "Start accepting global insurance",
            providersLandingPageMainValuePropHeading:
                "Be there for your clients",
            providersLandingPageMainValuePropDescription:
                "Everything you need to manage your international clients",
            providersLandingPageMainValuePropClaimOneHeading:
                "Delight your Patients",
            providersLandingPageMainValuePropClaimOneDescription:
                "Save your clients from the stress of insurance claims: accept their international insurance today.",
            providersLandingPageMainValuePropClaimTwoHeading: "Get Paid Fast",
            providersLandingPageMainValuePropClaimTwoDescription:
                "NomadRx Billing is a global billing software platform built to save you time and get you paid faster.",
            providersLandingPageMainValuePropClaimThreeHeading:
                "Receive and Reconcile International Payments",
            providersLandingPageMainValuePropClaimThreeDescription:
                "Receive payments into local or international banks accounts with clear reconciliation on the transaction.",
            providersLandingPagePricingSectionHeader: "Pricing",
            providersLandingPagePricingHeading:
                "Simple pricing. Built for you.",
            providersLandingPagePricingSubheading:
                "Access a complete global healthcare payments platform with the right pricing for your practice",
            providersLandingPagePricingInfoboxHeading:
                "Your dedicated team of global healthcare experts",
            providersLandingPagePricingInfoboxSubheading:
                "A complete administrative platform built for your global practice to run smoothly - fully dedicated to the success of your practice and patients.",
            whatsIncluded: "What's included",
            providersLandingPagePricingWhatsIncludedClaim1: "No setup fees",
            providersLandingPagePricingWhatsIncludedClaim2: "No wire fees",
            providersLandingPagePricingWhatsIncludedClaim3: "No hidden fees",
            providersLandingPagePricingWhatsIncludedClaim4:
                "Easy to get started",
            providersLandingPagePricingWhatsIncludedClaim5:
                "Faster global payments",
            providersLandingPagePricingWhatsIncludedClaim6:
                "Collect more revenue",
            providersLandingPagePricingCustomPricingHeading: "Custom pricing",
            providersLandingPagePricingCustomPricingSubheading:
                "Contact us and we'll custom design a pricing plan specific for your practice",
            getStartedToday: "Get started today",
            providersLandingPageCtaHeading:
                "Ready to get started? Get in touch.",
            providersLandingPageCtaSubheading:
                "Access a global healthcare administration solution with simple pricing, or we'll design a package specifically for your practice.",
            scheduleADemo: "Schedule a demo",

            home: "Home",
            claims: "Claims",
            clients: "Clients",
            providerAdmin: "Provider Admin",
            dashboard: "Dashboard",
            payments: "Payments",
            payouts: "Payouts",
            signOut: "Sign Out",
            account: "Account",
            settings: "Settings",

            providerLoading: "Loading ...",
            providerNotAuthenticated: "Provider not authenticated.",
            providerPleaseSignBackIn: "Please sign back in",
            signInNow: "Sign in now",
            viewAll: "View all",
            name: "Name",
            insurance: "Insurance",
            status: "Status",
            created: "Created",

            client: "Client",
            createdOn: "Created on",
            approved: "Approved",
            notApproved: "Not approved",

            create: "Create",
            date: "Date",
            amount: "Amount",
            providerClaimsListTablePayoutHeading: "Payment",
            add: "Add",
        },
        es: {
            landingPageMainHero: "Aceptación de seguro médico sin fronteras",
            landingPageMainHeroSubHeading:
                "Estamos permitiendo que su seguro médico internacional funcione sin problemas dondequiera que esté.",
            landingPageRecentNewsAnnouncement:
                "Anuncio de nuestro nuevo socio, TuDOC.",
            landingPageRecentNewsCallToAction: "Regístrese como paciente",
            getStarted: "Empezar",
            learnMore: "Aprende más",
            allRightsReserved: "Reservados todos los derechos.",
            // forPatients: "Para Pacientes",
            // forProviders: "Para Médicos",
            // yourAccount: "Su cuenta",
            // yourClinic: "Dashboard",
            // signIn: "Iniciar sesión",
            providerHomeBannerFirstLine: "Revisión de seguros en minutos.\n",
            providerHomeBannerSecondLine:
                "Envíe el seguro de su paciente ahora.\n",
            providerHomeBannerCTAButtonText: "Enviar seguro ahora",
            providerMostRecentClientListHeading:
                "Aprobaciones de seguros recientes",
            whatsNew: "Qué hay de nuevo",
            nowInMexico: "Ahora en México",
            providersLandingPageHeroHeading:
                "La plataforma sanitaria para la atención internacional.",
            providersLandingPageHeroDescriptionText:
                "NomadRx es la forma más fácil para que su práctica administre a sus clientes internacionales. Proporcione una excelente experiencia al paciente, reciba pagos rápidamente y acepte seguros internacionales.",
            providersLandingPageInsuranceCloudHeader:
                "Empiece a aceptar seguros globales",
            providersLandingPageMainValuePropHeading:
                "Esté ahí para sus pacientes",
            providersLandingPageMainValuePropDescription:
                "Todo lo que necesitas para gestionar a tus pacientes internacionales",
            providersLandingPageMainValuePropClaimOneHeading:
                "Deleite a sus pacientes",
            providersLandingPageMainValuePropClaimOneDescription:
                "Evite a sus clientes el estrés de las reclamaciones de seguros: acepte su seguro internacional hoy.",
            providersLandingPageMainValuePropClaimTwoHeading:
                "Reciba pagos rápidos",
            providersLandingPageMainValuePropClaimTwoDescription:
                "NomadRx Billing es una plataforma de software de facturación global creada para ahorrarle tiempo y pagarle más rápido.",
            providersLandingPageMainValuePropClaimThreeHeading:
                "Recibir y conciliar pagos internacionales",
            providersLandingPageMainValuePropClaimThreeDescription:
                "Reciba pagos en cuentas bancarias locales o internacionales con una conciliación clara de la transacción.",
            providersLandingPagePricingSectionHeader: "Precios",
            providersLandingPagePricingHeading:
                "Precios sencillos. Construido para ti.",
            providersLandingPagePricingSubheading:
                "Acceda a una completa plataforma global de pagos de atención médica con el precio adecuado para su práctica",
            providersLandingPagePricingInfoboxHeading:
                "Su equipo dedicado de expertos en atención médica global",
            providersLandingPagePricingInfoboxSubheading:
                "Una plataforma administrativa completa creada para que su práctica global funcione sin problemas, totalmente dedicada al éxito de su práctica y de sus pacientes.",
            whatsIncluded: "Qué está incluido",
            providersLandingPagePricingWhatsIncludedClaim1:
                "Sin tarifas de instalación",
            providersLandingPagePricingWhatsIncludedClaim2:
                "Sin cargos por transferencia bancaria",
            providersLandingPagePricingWhatsIncludedClaim3:
                "Sin cuotas escondidas",
            providersLandingPagePricingWhatsIncludedClaim4: "Fácil de empezar",
            providersLandingPagePricingWhatsIncludedClaim5:
                "Pagos globales más rápidos",
            providersLandingPagePricingWhatsIncludedClaim6:
                "Recaudar más ingresos",
            providersLandingPagePricingCustomPricingHeading:
                "Precios personalizados",
            providersLandingPagePricingCustomPricingSubheading:
                "Contáctenos y diseñaremos un plan de precios personalizado y específico para su práctica.",
            getStartedToday: "Empiece hoy",
            providersLandingPageCtaHeading:
                "¿Listo para comenzar? Ponerse en contacto.",
            providersLandingPageCtaSubheading:
                "Acceda a una solución global de administración de atención médica con precios simples o diseñaremos un paquete específicamente para su práctica.",
            scheduleADemo: "Programe una demostración",

            home: "Inicio",
            claims: "Pagos",
            clients: "Pacientes",
            providerAdmin: "Administrador de clínica",
            dashboard: "Panel",
            payments: "Pagos",
            payouts: "Transferencias",
            signOut: "Cerrar sesión",
            account: "Perfil",
            settings: "Configuración",

            providerLoading: "Cargando ...",
            providerNotAuthenticated: "Proveedor médico no autenticado.",
            providerPleaseSignBackIn: "Por favor vuelve a iniciar sesión",
            signInNow: "Regístrate",
            viewAll: "Ver todo",
            name: "Nombre",
            insurance: "Seguro",
            status: "Estado",
            created: "Creada",

            client: "Paciente",
            createdOn: "Creada en",
            approved: "Seguro aprobado",
            notApproved: "Seguro no aprobado",

            create: "Crear",
            date: "Fecha",
            amount: "Monto",
            providerClaimsListTablePayoutHeading: "Pago recibido",
            add: "Agrega",
        },
    },
}));
